import classNames from "classnames";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { FirebaseError } from "firebase/app";

export type FormErrorProps = {
  error: FirebaseError;
  className?: string;
};

function FormError({ error, className }: FormErrorProps) {
  return (
    <div className={classNames("rounded-md bg-red-50 p-4", className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            There was an error with saving your data
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul role="list" className="list-disc pl-5 space-y-1">
              <li>
                Code: {error.code}. Message: {error.message}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormError;
