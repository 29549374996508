import { Timestamp } from "firebase/firestore";
import { isNonEmptyArray, stringValueExists } from "@/methods/utilities";
import {
  type MemberProfile,
  type User,
  type FormValue,
  type Event,
  FormQuestion,
  Schema,
} from "@/types";

/**
 * Merges two profiles, b values are merged ontop of a's values.
 * @param {MemberProfile} a  - First profile
 * @param {MemberProfile} b - Second Profile
 * @returns {MemberProfile} A merged profile with b's values merged with priority of a's.
 */
export const mergeProfiles = (
  a?: Partial<MemberProfile>,
  b?: Partial<MemberProfile>
): MemberProfile => {
  const { DEMOGRAPHICS: aDemographics = {}, ...aRest } = a || {};
  const { DEMOGRAPHICS: bDemographics = {}, ...bRest } = b || {};
  const merged: MemberProfile = {
    ...aRest,
    ...bRest,
    DEMOGRAPHICS: {
      ...aDemographics,
      ...bDemographics,
    },
  } as MemberProfile;

  return merged;
};

export const mergeAllProfiles = (profiles: Array<MemberProfile>) =>
  profiles.reduce<MemberProfile>(
    (a, b) => mergeProfiles(a, b),
    {} as MemberProfile
  );

export const getMemberName = (
  member: MemberProfile,
  fillInNull = true
): string => {
  let fullName = "";

  if (
    isNonEmptyArray(member?.["Full Name"]) &&
    stringValueExists(member?.["Full Name"]?.[0])
  ) {
    fullName = member["Full Name"][0];
  } else if (stringValueExists(member?.["Full Name"])) {
    fullName = member?.["Full Name"];
  } else if (member?.["Full Name"] === null && fillInNull) {
    fullName = "Unnamed";
  }

  return fullName;
};

export const getMemberEmail = (member: MemberProfile): string | null => {
  let email: string | null = null;

  if (
    isNonEmptyArray(member["Email"]) &&
    stringValueExists(member?.["Email"]?.[0])
  ) {
    email = member["Email"][0];
  } else if (stringValueExists(member?.["Email"])) {
    email = member?.["Email"];
  }

  return email;
};

export const getDefaultFormValues = (
  user: User,
  event: Event,
  existingDetails: MemberProfile
): Record<string, FormValue> => {
  const startTags = event?.tags || [];
  const { DEMOGRAPHICS = {}, ...fromTTS } = existingDetails;
  const flattenedExistingProfile = { ...DEMOGRAPHICS, ...fromTTS };
  const arrayKeysToChange = ["Email", "Full Name", "Postcode", "Phone"];
  const cleanedUp = Object.keys(flattenedExistingProfile).reduce((obj, key) => {
    if (flattenedExistingProfile[key] instanceof Timestamp) {
      obj[key] = flattenedExistingProfile[key].toDate();
    } else if (
      arrayKeysToChange.includes(key) &&
      Array.isArray(flattenedExistingProfile[key])
    ) {
      obj[key] = flattenedExistingProfile[key][0];
    } else {
      obj[key] = flattenedExistingProfile[key];
    }
    return obj;
  }, {});
  return {
    ...cleanedUp,
    Email: user?.email,
    "Full Name":
      getMemberName(flattenedExistingProfile as MemberProfile, false) || "",
    tags: flattenedExistingProfile?.tags
      ? [...new Set([...startTags, ...flattenedExistingProfile.tags])]
      : startTags,
  };
};

export const getApplicableAnswers = (
  profileData: Record<string, FormValue>,
  questions: Record<string, FormQuestion>,
  schema: Schema,
  arrayFields = ["Email", "Postcode", "Full Name", "Phone", "tags"]
) =>
  Object.keys(profileData || {})
    .filter((key) => questions[key] || key === "tags")
    .filter((key) => profileData[key])
    .reduce(
      (obj, key) => {
        if (schema?.DEMOGRAPHICS?.[key]) {
          obj.DEMOGRAPHICS = { ...obj.DEMOGRAPHICS, [key]: profileData[key] };
        } else if (arrayFields?.includes(key)) {
          obj[key] = Array.isArray(profileData[key])
            ? profileData[key]
            : [profileData[key]];
        } else {
          obj[key] = profileData[key];
        }
        return obj;
      },
      { DEMOGRAPHICS: {} }
    );
