import { ChangeEvent, type FormEventHandler } from "react";
import { FirebaseError } from "firebase/app";

import { XCircleIcon } from "@heroicons/react/24/solid";
import FormQuestion from "@/components//FormQuestion";
import {
  type FormQuestion as FormQuestionType,
  type Schema,
} from "@/types";
import checkForConditions from "@/methods/checkForConditions";
import { isNonEmptyArray } from "@/methods/utilities";

export type FormRendererProps = {
  className?: string;
  formQuestions: Record<string, FormQuestionType>;
  additionalQuestions: Array<FormQuestionType>;
  formValues: Record<string, number | string | boolean | Array<string>>;
  schema: Schema;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
  color?: string | undefined;
  textColor?: string | undefined;
  label?: string | undefined;
  loading?: boolean | undefined;
  saving?: boolean | undefined;
  hideSave?: boolean | undefined;
  noMaxHeight?: boolean | undefined;
  error?: FirebaseError | undefined;
};

function FormRenderer({
  formQuestions,
  formValues,
  schema,
  onChange,
  onSubmit,
  additionalQuestions = [],
  label = "Save",
  loading = false,
  saving = false,
  hideSave = false,
  noMaxHeight = false,
  error,
  textColor,
  color,
  className,
}: FormRendererProps) {
  const relevantQuestions = Object.keys(formQuestions || {})
    .filter((key) => key !== "Email" && key !== "tags")
    .filter((key) => checkForConditions(formQuestions[key], formValues))
    .sort((a, b) => formQuestions[a].order - formQuestions[b].order)
    .map((key) => ({ ...formQuestions[key], _id: key }));

  return (
    <form onSubmit={onSubmit} className={className}>
      <div
        style={{ maxHeight: noMaxHeight ? "" : "calc(100vh - 260px)" }}
        className="overflow-y-auto space-y-3 p-4 rounded-lg border-gray-300 border bg-gray-50 mb-4 pb-4"
      >
        {error && (
          <div className="rounded-md bg-red-50 p-4 border border-red-600">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  An error has occurred related to this form.
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  <ul role="list" className="list-disc pl-5 space-y-1">
                    <li>
                      Code: {error.code}. Message: {error.message}
                    </li>
                    {error.code === "unavailable" && (
                      <li>
                        If you still have internet connection, access might be
                        being blocked by a firewall.
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {isNonEmptyArray(relevantQuestions) ||
        isNonEmptyArray(additionalQuestions) ? (
          <>
            {relevantQuestions.map((question) => (
              <FormQuestion
                key={question.id || question._id}
                question={question}
                value={formValues?.[question.id || question._id]}
                schemaObj={
                  schema?.[question._id] || schema?.DEMOGRAPHICS?.[question._id]
                }
                onChange={onChange}
              />
            ))}
            {additionalQuestions.map((question) => (
              <FormQuestion
                key={question.id || question._id}
                question={question}
                value={formValues?.[question.id || question._id]}
                schemaObj={question}
                onChange={onChange}
              />
            ))}
          </>
        ) : (
          <div className="text-center text-gray-600">
            No extra details required. You can add your booking to your basket
            below!
          </div>
        )}
      </div>
      {!hideSave && (
        <button
          disabled={loading || saving}
          style={{
            backgroundColor: (!(loading && !saving) && color) || undefined,
            color: (!(loading && !saving) && textColor) || undefined,
          }}
          type="submit"
          className="disabled:bg-gray-200 text-primary disabled:cursor-not-allowed py-2 btn-primary w-full"
        >
          {label}
        </button>
      )}
    </form>
  );
}

export default FormRenderer;
