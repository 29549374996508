/* eslint-disable react/jsx-props-no-spreading */
import classNames from "classnames";
import FieldComponent from "@/components/FieldComponent";
import { type SchemaField, type FormQuestion } from "@/types";
import { ChangeEvent } from "react";

export type FormQuestionProps = {
  className?: string;
  question: FormQuestion;
  value: boolean | string | number | Array<string> | undefined;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  schemaObj: Partial<SchemaField>;
};

function FormQuestion({
  question,
  value,
  onChange,
  schemaObj,
  className,
}: FormQuestionProps) {
  if (question.type === "divider") {
    return (
      <div className="border-b border-greyClear text-xl font-medium pb-2">
        <div
          className={classNames(
            "h-4 first:hidden",
            className
          )}
        />
        {question.label || "Section"}
      </div>
    );
  }

  return (
    <>
      <label
        key={question.id || question._id}
        htmlFor={question.id || question._id}
        className={classNames(
          "block text-sm font-medium text-gray-700",
          className
        )}
      >
        {question.name || question.label}
        <span className="italic font-normal">
          {question.required ? (
            <span className="text-red-500 leading-4 text-lg"> * </span>
          ) : (
            " - optional"
          )}
        </span>
      </label>
      <FieldComponent
        {...question}
        id={question.id || question._id}
        placeholder={question.name || question.label}
        value={value}
        schemaObj={schemaObj}
        onChange={onChange}
        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-greyClear rounded-md max-w-lg"
      />
    </>
  );
}

export default FormQuestion;
