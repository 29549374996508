import React, { useContext, useState, useMemo } from "react";
import PropTypes from "prop-types";

import UserContext from "./UserContext";
import Spinner from "./Spinner";
import { saveOrCreateDetails } from "@/methods/dbProfiles";
import useForm from "@/hooks/useForm";
import useSchema from "@/hooks/useSchema";
import FormRenderer from "./FormRenderer";
import { isNonEmptyArray } from "@/methods/utilities";
import FormError from "@/features/programme-booking/components/FormError";
import { getApplicableAnswers, getDefaultFormValues, mergeProfiles } from "@/methods/profiles";

const arrayFields = ["Email", "Postcode", "Full Name", "Phone", "tags"];

const ExtraDetails = ({
  orgId,
  label,
  hideSave,
  formId,
  eventData,
  color,
  textColor,
  existingDetails,
  excludeFieldIds,
  noDetailsExist,
  loading,
  noMaxHeight,
  onFinish,
  additionalQuestions = [],
}) => {
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);
  const [formValues, setFormValues] = useState({});
  const [saving, setSaving] = useState(false);

  const defaultFormValues = useMemo(() => {
    if (!noDetailsExist) {
      return getDefaultFormValues(user, eventData, existingDetails);
    } else {
      return {};
    }
  }, [noDetailsExist, existingDetails, user, eventData]);

  console.log({ formValues, noDetailsExist });

  const [formData, formLoading, formLoadingError] = useForm(formId);
  const [schema, schemaLoading, schemaLoadingError] = useSchema(orgId);

  const handleInputChange = (e) => {
    const { id, value, checked, type } = e.target;
    setFormValues({
      ...formValues,
      [id]: value,
    });
  };

  const additionalAnswers = additionalQuestions.reduce((obj, question) => {
    obj[question.id] = formValues[question.id];
    return obj;
  }, {});

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (saving) {
      return;
    }

    setSaving(true);

    const data = { ...defaultFormValues, ...formValues };
    // Only update the values that are in this form
    const toSave = getApplicableAnswers(
      data,
      formData?.questions || {},
      schema,
      arrayFields,
    );

    try {
      if (user?.uid) {
        const { memberId } = await saveOrCreateDetails(
          user.uid,
          orgId,
          existingDetails,
          toSave
        );

        onFinish(memberId, additionalAnswers, mergeProfiles(existingDetails, toSave));
      }
    } catch (err) {
      // TO DO - handle error here
      console.error(err);
      setError(err);
    } finally {
      setSaving(false);
    }
  };

  const mergedFormValues = { ...defaultFormValues, ...formValues };

  let formQuestions = formData?.questions || {};

  if (isNonEmptyArray(excludeFieldIds)) {
    formQuestions = Object.keys(formQuestions)
      .filter(
        (key) =>
          !excludeFieldIds ||
          !excludeFieldIds.length ||
          !excludeFieldIds.includes(key)
      )
      .reduce((lookup, key) => {
        lookup[key] = formData.questions[key];
        return lookup;
      }, {});
  }

  return (
    <>
      {error && (
        <FormError error={error} />
      )}
      {loading || formLoading || schemaLoading ? (
        <div className="flex items-center justify-center h-64 w-full">
          <Spinner loading />
        </div>
      ) : (
        <FormRenderer
          formQuestions={formQuestions}
          formValues={mergedFormValues}
          schema={schema}
          onChange={handleInputChange}
          onSubmit={handleSubmit}
          additionalQuestions={additionalQuestions}
          label={label}
          loading={loading || formLoading || schemaLoading}
          saving={saving}
          hideSave={hideSave}
          noMaxHeight={noMaxHeight}
          error={formLoadingError || schemaLoadingError}
          textColor={textColor}
          color={color}
        />
      )}
    </>
  );
};

ExtraDetails.propTypes = {
  orgId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  excludeFieldIds: PropTypes.arrayOf(PropTypes.string),
  eventData: PropTypes.shape({}).isRequired,
  existingDetails: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  color: PropTypes.string,
  textColor: PropTypes.string,
  onFinish: PropTypes.func,
  label: PropTypes.string,
  noDetailsExist: PropTypes.bool,
};

ExtraDetails.defaultProps = {
  label: "Save",
  excludeFieldIds: [],
  existingDetails: undefined,
  color: null,
  textColor: null,
  noDetailsExist: false,
};

export default ExtraDetails;
