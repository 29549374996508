import { useEffect, useState } from "react";
import { FirebaseError } from "firebase/app";
import { getDoc, doc, getFirestore } from "firebase/firestore";
import * as Sentry from "@sentry/nextjs";

import {
  ORGANISATIONS_COLLECTION,
  SCHEMAS_COLLECTION,
  MASTER_SCHEMA_KEY,
} from "@/constants/collections";
import { stringValueExists } from "@/methods/utilities";
import { Schema } from "@/types";

function useSchema(
  organisationId?: string | undefined
): [Schema | undefined, boolean, FirebaseError | null] {
  const [schema, setSchema] = useState<Schema | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<FirebaseError | null>(null);

  useEffect(() => {
    let ignore = false;
    if (stringValueExists(organisationId)) {
      const db = getFirestore();
      setLoading(true);
      getDoc(
        doc(
          db,
          ORGANISATIONS_COLLECTION,
          organisationId,
          SCHEMAS_COLLECTION,
          MASTER_SCHEMA_KEY
        )
      )
        .then((rawDoc) => {
          if (!ignore) {
            setSchema({ ...rawDoc.data() } as Schema);
          }
        })
        .catch((schemaErr) => {
          Sentry.captureException(schemaErr);
          setError(schemaErr);
        })
        .finally(() => {
          if (!ignore) {
            setLoading(false);
          }
        });
      return () => {
        ignore = true;
      };
    }
  }, [organisationId]);

  return [schema, loading, error];
}

export default useSchema;
