/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  doc,
  getDoc,
  getFirestore,
  collection,
  arrayUnion,
  writeBatch,
  serverTimestamp,
  where,
  getDocs,
  query,
  DocumentReference,
  DocumentData,
} from "firebase/firestore";
import {
  MEMBERS_COLLECTION,
  PROFILE_COLLECTION,
  USER_COLLECTION,
} from "@/constants/collections";
import { type MemberProfile } from "@/types";
import { stringValueExists } from "./utilities";

const db = getFirestore();

export type ProfileChangeMeta = {
  memberId: string;
  profileId: string;
};

export const generateId = (): string => doc(collection(db, "GenerateID")).id;

export const doesProfileExist = async (
  userId: string,
  profileId: string
): Promise<boolean> => {
  const document = await getDoc(doc(db, `User/${userId}/Profiles`, profileId));
  return document.exists();
};

export const loadExistingAdminProfiles = async (
  userId: string
): Promise<Array<MemberProfile>> => {
  const existingAdminProfiles = await getDocs(
    query(
      collection(db, USER_COLLECTION, userId, PROFILE_COLLECTION),
      where("Admin", "==", true)
    )
  );
  const adminProfileData = existingAdminProfiles.docs.map<MemberProfile>(
    (rawDoc) => ({ ...rawDoc.data(), _id: rawDoc.id } as MemberProfile)
  );
  return adminProfileData;
};

export const saveMemberAndCreateProfile = async (
  userId: string,
  memberId: string,
  memberData: Partial<MemberProfile>,
  profileData: Partial<MemberProfile>,
  orgId: string
): Promise<ProfileChangeMeta> => {
  const userProfileRef = doc(collection(db, `User/${userId}/Profiles`));
  const memberRef = doc(db, MEMBERS_COLLECTION, memberId);

  const batch = writeBatch(db);

  batch.set(
    memberRef,
    {
      ...memberData,
      userProfileId: userProfileRef.id,
      User: userId,
      updated: serverTimestamp(),
    },
    { merge: true }
  );

  batch.set(
    userProfileRef,
    {
      ...profileData,
      created: serverTimestamp(),
      orgIds: arrayUnion(orgId),
    },
    { merge: true }
  );

  await batch.commit();

  return {
    memberId: memberRef.id,
    profileId: userProfileRef.id,
  };
};

export const saveMemberAndUpdateProfile = async (
  userId: string,
  memberId: string,
  memberData: Partial<MemberProfile>,
  profileId: string,
  profileData: Partial<MemberProfile>,
  orgId: string
): Promise<ProfileChangeMeta> => {
  const { DEMOGRAPHICS = {}, ...restOfExisting } = profileData;
  const demographicUpdateStrings = Object.keys(DEMOGRAPHICS).reduce(
    (obj, key) => {
      obj[`DEMOGRAPHICS.${key}`] = DEMOGRAPHICS[key];
      return obj;
    },
    {}
  );

  const memberRef = doc(db, MEMBERS_COLLECTION, memberId);
  const existingProfileRef = doc(db, `User/${userId}/Profiles`, profileId);

  const batch = writeBatch(db);

  batch.set(
    memberRef,
    {
      ...memberData,
      userProfileId: existingProfileRef.id,
      User: userId,
      updated: serverTimestamp(),
    },
    { merge: true }
  );

  batch.update(existingProfileRef, {
    ...restOfExisting,
    ...demographicUpdateStrings,
    updated: serverTimestamp(),
    orgIds: arrayUnion(orgId),
  });

  await batch.commit();

  return {
    memberId: memberRef.id,
    profileId: existingProfileRef.id,
  };
};

export const createMemberAndProfile = async (
  userId: string,
  orgId: string,
  memberData: Partial<MemberProfile>,
  existingDetails: Partial<MemberProfile> & { sourceId?: string | undefined }
): Promise<ProfileChangeMeta> => {
  const toSave = { ...memberData };
  const newMemberDocRef = doc(collection(db, MEMBERS_COLLECTION));
  const userProfilesPath = `User/${userId}/Profiles`;
  let newUserProfileRef: DocumentReference<DocumentData, DocumentData>;

  if (stringValueExists(existingDetails?.sourceId)) {
    newUserProfileRef = doc(db, userProfilesPath, existingDetails?.sourceId);
    toSave.userProfileId = existingDetails?.sourceId;
  } else if (stringValueExists(existingDetails.userProfileId)) {
    newUserProfileRef = doc(db, userProfilesPath, existingDetails?.userProfileId);
    toSave.userProfileId = existingDetails?.userProfileId;
  } else {
    newUserProfileRef = doc(collection(db, userProfilesPath));
    toSave.userProfileId = newUserProfileRef.id;
  }

  if (existingDetails?.funderMemberId) {
    toSave.funderMemberId = existingDetails?.funderMemberId;
  }

  const batch = writeBatch(db);

  batch.set(
    newMemberDocRef,
    {
      userProfileId: newUserProfileRef.id,
      DEMOGRAPHICS: {},
      ...toSave,
      User: userId,
      managedBy: orgId,
      created: new Date(),
      updated: new Date(),
      userCreated: true,
    },
    { merge: true }
  );

  const {
    managedBy,
    tags,
    _id,
    funderMemberId,
    userProfileId,
    source,
    ...rest
  } = toSave;

  batch.set(
    newUserProfileRef,
    {
      ...rest,
      updated: new Date(),
      orgIds: arrayUnion(orgId),
    },
    { merge: true }
  );

  await batch.commit();

  return {
    memberId: newMemberDocRef.id,
    profileId: newUserProfileRef.id,
  };
};

export const saveOrCreateDetails = async (
  userId: string,
  organisationId: string,
  existingDetails: MemberProfile,
  newDetails: Partial<MemberProfile> & { sourceId?: string }
): Promise<ProfileChangeMeta> => {

  if (existingDetails?._id) {
    const profileIdToUse =
      existingDetails?.userProfileId ||
      existingDetails?.sourceId ||
      existingDetails?._id;

    const hasExistingProfile = await doesProfileExist(userId, profileIdToUse);

    // update the core profile
    const {
      managedBy,
      tags,
      _id,
      funderMemberId,
      userProfileId,
      source,
      sourceId,
      Admin,
      orgIds,
      ...restOfProfile
    } = newDetails;

    if (!hasExistingProfile) {
      if (existingDetails?.Admin) {
        // If we're an admin updating our profile & we don't have a profile
        // Try and find the admin profile setup on account creation & connect to it.
        const adminProfileData = await loadExistingAdminProfiles(userId);

        // An admin profile exists, we connect this PersonalData document to it & update the underyling profile
        if (adminProfileData.length > 0) {
          return saveMemberAndUpdateProfile(
            userId,
            existingDetails?._id,
            newDetails,
            adminProfileData[0]._id,
            restOfProfile,
            organisationId
          );
        } else {
          return saveMemberAndCreateProfile(
            userId,
            existingDetails._id,
            newDetails,
            {
              ...restOfProfile,
              Admin: true,
            },
            organisationId
          );
        }
      } else {
        return saveMemberAndCreateProfile(
          userId,
          existingDetails._id,
          newDetails,
          restOfProfile,
          organisationId
        );
      }
    } else {
      return saveMemberAndUpdateProfile(
        userId,
        existingDetails._id,
        newDetails,
        profileIdToUse,
        restOfProfile,
        organisationId
      );
    }
  } else {
    return createMemberAndProfile(
      userId,
      organisationId,
      newDetails,
      existingDetails
    );
  }
};
