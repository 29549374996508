import { useEffect, useState } from "react";
import { FirebaseError } from "firebase/app";
import { getDoc, doc, getFirestore } from "firebase/firestore";
import * as Sentry from "@sentry/nextjs";

import { FORMS_COLLECTION } from "@/constants/collections";
import { stringValueExists } from "@/methods/utilities";
import { Form } from "@/types";

function useForm(
  formId?: string | undefined
): [Form | undefined, boolean, FirebaseError | null] {
  const [form, setForm] = useState<Form | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<FirebaseError | null>(null);

  useEffect(() => {
    let ignore = false;
    if (stringValueExists(formId)) {
      const db = getFirestore();
      setLoading(true);
      getDoc(doc(db, FORMS_COLLECTION, formId))
        .then((formDoc) => {
          if (!ignore) {
            setForm({ ...formDoc.data(), _id: formDoc.id } as Form);
          }
        })
        .catch((formErr) => {
          Sentry.captureException(formErr);
          setError(formErr);
        })
        .finally(() => {
          if (!ignore) {
            setLoading(false);
          }
        });
      return () => {
        ignore = true;
      };
    }
  }, [formId]);

  return [form, loading, error];
}

export default useForm;
